import React from "react";
import Home from "../component/Home/Home";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import "../styles/global.css";
import citySpecificImage from "../images/cities/solana_beach.png";

const App = () => {
  const pageData = {
    residentialRealtors: {
      title: "Realtors in Solana Beach, CA",
      subtitle: "With Your Best Interests At Heart",
      description:
        "This is about more than a deal—it's about your family's happiness and building a great future. So whether you're interested in Solana Beach homes for sale, are looking to sell your house, or would like to explore property investment options, our experienced residential real estate professionals will connect you with the best opportunities. And we'll do it with heart because we're a family too.",
    },
    findNextHome: {
      card1Title: "Homes for Sale in Solana Beach",
      card2Title: "What Is Your Home Worth?",
    },
    meetFamily: {
      title: "Meet Our Family",
      subtitle:
        "We’re dedicated realtors who help families like yours throughout the homeownership journey—from buying houses for sale in Solana Beach to selling to investing. We’re advisors who are in your corner from day one.",
    },
    citySpecific: {
      titlePartA: "Happily Serving",
      titlePartB: "Solana Beach",
      p1: "Annie’s Canyon Trail. Fletcher Cove Park. Solana Beach Farmers Market. If you love the outdoors, this vibrant community is the perfect place to buy a home or invest in property. Prefer the comfort of the indoors? There’s plenty for you to enjoy as well, like the North Coast Repertory Theatre, an abundance of restaurants, and impressive art galleries.",
      p2: "Ready to learn more about this city and all it offers? Contact our friendly team of professionals no matter what your Solana Beach, CA, real estate needs may be. We can help you find the perfect home, sell one in the area, or explore property investment options.",
      image: citySpecificImage,
    },
    cta: {
      description:
        "Connect with a top-rated team of Solana Beach realtors who listen, are readily available, and will work tirelessly for you.",
    },
  };
  return (
    <LayoutWrapper
      title="Solana Beach Homes for Sale | Real Estate | Selby Realtors"
      desc="Find homes for sale in Solana Beach, where life is about sun, surf, and great vibes. Call The Selby Team to learn more about this San Diego beach community!"
      headerLogo="../../images/Selby_logo.svg"
    >
      <Home pageData={pageData} />
    </LayoutWrapper>
  );
};

export default App;
